import React from 'react'
import { useIntersectionObserver } from 'react-intersection-observer-hook'
import { GlobalStyles } from '../misc/globalStyles'
import {
  Blog,
  Cooperation,
  Events,
  Footer,
  Gallery,
  Navbar,
  StayUpdated,
  Hero,
  ScrollUp,
  Seo,
} from '../components'
import { MainWrapper } from '../components/layoutComponents'

export default function IndexPage() {
  const [ref, { entry }] = useIntersectionObserver()
  const isHeroVisible = entry && entry.isIntersecting

  return (
    <MainWrapper>
      <Seo />
      <GlobalStyles />
      <Navbar hasBackground={!isHeroVisible} />
      <Hero ref={ref} />
      <Events />
      <Cooperation />
      <StayUpdated />
      <Gallery />
      <Blog />
      <Footer />
      <ScrollUp isVisible={!isHeroVisible} />
    </MainWrapper>
  )
}
